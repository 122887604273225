import React, { useEffect,useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/home/jumbotron"
import LearnMore from "../components/learnmore"
import Service from "../components/home/service"
import Brand from "../components/home/brand"
import Applications from "../components/home/applications"
// import ScrollReveal from 'scrollreveal'

const IndexPage = () => {

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" description="Sell anytime, anywhere to anyone. " />
      {/* 大图 */}
      <Jumbotron />
      {/* service item */}
      <Service />
      {/* Brand Guarantee */}
      <Brand />
      {/* Plentiful applications Easy operation */}
      <Applications />
      {/* learn more */}
      {/* <LearnMore /> */}
    </Layout>
  )
}

export default IndexPage
