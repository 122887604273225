import React from "react"
import { Link } from 'gatsby'
import { Button } from "react-bootstrap"

const Applications = () => (
  <div className='application foo1'>
    <div
      className="title foo1">Shoprises APP STORE</div>
      <div
      className="text foo1">WE FOCUS ON APPS THAT WORK FOR YOUR BUSINESS.</div>
    <span className="temp5"></span>
    <div className="application-list">
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconPromotioncountdown"/>
        </svg>
        <div className="item-title">Countdown Timer</div>
        <div className="item-text">It’s not horrible boost sales with urgency, and nudge customers to Buy Now</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconGoogleSitemap"/>
        </svg>
        <div className="item-title">Sitemap for Google</div>
        <div className="item-text">Generate and submit a sitemap to Google to improve the SEO performance of your website.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconRecentpurchasehist"/>
        </svg>
        <div className="item-title">Add Sales Pop up for social proof</div>
        <div className="item-text">Sales popup will let you show recent sales notifications to build trust and social proof to influence visitors to buy from you.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconMabangERP"/>
        </svg>
        <div className="item-title">Sync Inventory</div>
        <div className="item-text">Sync & manage inventory, orders & more on Mabang ERP.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconPixelConversion"/>
        </svg>
        <div className="item-title">Multiple Facebook Pixels Manager</div>
        <div className="item-text">Track multiple pixels, segment your customers to build custom audiences.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconQuicklyaddtocart"/>
        </svg>
        <div className="item-title">Sticky Add to Cart</div>
        <div className="item-text">Boost your Revenue with an easily accessible Add to Cart button.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconRecommendedproducts"/>
        </svg>
        <div className="item-title">Product Recommendation</div>
        <div className="item-text">Upsell, Related Products, Product Recommendations.Drive sales, segment your customers and grow your audience.</div>
      </div>
      <div className="application-list-item foo1">
        <svg className="icon">
          <use href="#iconFreightinsurance"/>
        </svg>
        <div className="item-title">Shipping Insurance</div>
        <div className="item-text">Add Custom Shipping Insurance and increase sales revenue.The analysis shows online shop offering a shipping option have a higher conversion.</div>
      </div>
    </div>
    <span className="temp6"></span>
    {/* <Button className="btn foo1"><Link to="/contact">Learn More</Link></Button> */}
  </div>
)


export default Applications
