import React ,{useEffect} from "react"
import { Link} from 'gatsby'
import { Carousel,Button } from "react-bootstrap"
import ScrollReveal from 'scrollreveal'
import item1 from '../../assets/images/home/item1.png'
import item2 from '../../assets/images/home/item2.png'
import item3 from '../../assets/images/home/item3.png'

const Service = () => {
  useEffect(()=> {
    ScrollReveal().reveal('.foo1',
      {
        delay: 0, // 延迟
        distance: '50px',
        easing: 'ease-in-out',
        origin: "bottom",
        interval: 50,
        duration: 400,
        mobile: false,
        opacity : 0, 
        init: false,
      }
    )
  })
  return (
    <>
      <div className='web-service foo1'>
        {/* 奇奇怪怪的图标 */}
        <span className='temp1 foo1'></span>
        <span className='temp2 foo1'></span>
        <span className='temp3 foo1'></span>
        <span className='temp4 foo1'></span>

        {/* <div style={{background:'rgb(16, 128, 67)'}}>哈哈哈哈哈哈</div> */}
        
        <div className='title foo1'>Our Services</div>
        
        {/* <Carousel interval={null}>
          <Carousel.Item> */}
            <div  style={{display:"flex",justifyContent:'space-between'}}>
              <div className='items foo1' >
                {/* 小蓝点 */}
                <div className='dot'></div>
                {/* 图标 */}
                <img src={item1} className="item-bg"/>
                {/* 标题 */}
                <div>Build your brand</div>
                {/* 描述 */}
                <div>Look professional and help customers connect with your business.</div>
                {/* <Button className="btn"><Link to="/contact" >Learn More</Link></Button> */}
                {/* <Link to="/contact">———  Start building</Link> */}
              </div>
              <div className='items foo1'>
                <div className='dot'></div>
                <img src={item2} className="item-bg"/>
                <div>Build your online presence</div>
                <div>Put your brand into action—promote your business online with digital marketing and develop customer loyalty.</div>
                {/* <Button className="btn"><Link to="/contact">Learn More</Link></Button> */}
                {/* <Link to="/contact">———  Start building</Link> */}
              </div>  
              <div className='items foo1'>
                <div className='dot'></div>
                <img src={item3} className="item-bg"/>
                <div>Build your store</div>
                <div>Bring your business online,creating a website and drive sales 24/7.</div>
                {/* <Button className="btn"><Link to="/contact">Learn More</Link></Button> */}
                {/* <Link to="/contact">———  Start building</Link> */}
              </div>     
            </div>
      </div>
      <div className='pro-service'>
        <div className='title foo1'>Our Services</div>
            
              <div className='items foo1 '>
                <img src={item1} className="item-bg"/>
                <div>
                <div>Build your brand</div>
                {/* 描述 */}
                <div>Look professional and help customers connect with your business.</div>
                </div>
              </div>
              {/* <div className="more"><Link to="/contact">Learn More {">"}</Link></div> */}
              <div className='items foo1'>
                <img src={item2} className="item-bg"/>
                <div>
                <div>Build your online presence</div>
                <div>Put your brand into action—promote your business online with digital marketing and develop customer loyalty.</div>
                </div>
              </div>   
              {/* <div className="more"><Link to="/contact">Learn More {">"}</Link></div>     */}
              <div className='items'>
                <img src={item3} className="item-bg"/>
                <div>
                <div>Build your store</div>
                <div>Bring your business online,creating a website and drive sales 24/7.</div>
                </div>
              </div>    
              {/* <div className="more"><Link to="/contact">Learn More {">"}</Link></div> */}
      </div>
    </>
  )
}

export default Service
